<template>
  <div class="containerWithArrow">
    <div>
      <div class="backSpace" @click="goBack"/>
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>이벤트</strong></p>
      </div>
    </div>
    <div class="mb30">
      <div class="surveyTileBox">
        <p class="mb10"><strong>{{ event.event_name }}</strong></p>
        <p class="light colorLightGrey">
          {{ event._start_apply }} ~ {{ event._end_apply }}
        </p>
        <div class="mt10"><span class="alertBox" :class="onEvent ? 'BgRedForSurvey' : 'redForSurvey'">{{stateTmp}}</span></div>
        <div class="mt30 eventBox">
          <img class="img" v-if="event.guide_pic" :src="event.guide_pic">
<!--          <div class="noImg"></div>-->
        </div>
        <p class="mb10 leftText" v-html="event.guide_contents"></p>
      </div>
    </div>
    <eagle-box
      v-show="!onEvent"
      :title="btnTmp.title"
      :sub-title="btnTmp.subTitle"
      :is-btn="btnTmp.isBtn"
      :btn-type="btnTmp.btnType"
      :btn-name="btnTmp.btnName"
    />
    <div class="mb30" v-show="isList && onEvent">
      <p class="lineTitle">이벤트 참여</p>
      <div class="tileBox">
        <div class="formGroup">
          <div class="label">이름</div>
          <div class="value">
            <input type="text" class="input" disabled v-model="form.name">
          </div>
        </div>
        <div class="formGroup">
          <div class="label">연락처</div>
          <div class="value">
            <input type="tel" class="input" disabled v-model="mobile">
          </div>
        </div>
        <div class="formGroup" v-if="event.pic1_name !== ''">
          <div class="label">{{ event.pic1_name }}</div>
          <div class="value">
            <input type="text" class="input" placeholder="입력하세요" maxlength="500" v-model="form.pic1_name">
          </div>
        </div>
        <div class="formGroup" v-if="event.pic2_name !== ''">
          <div class="label">{{ event.pic2_name }}</div>
          <div class="value">
            <input type="text" class="input" placeholder="입력하세요" maxlength="500"  v-model="form.pic2_name">
          </div>
        </div>
        <div class="formGroup" v-if="event.pic3_name !== ''">
          <div class="label">{{ event.pic3_name }}</div>
          <div class="value">
            <input type="text" class="input" placeholder="입력하세요" maxlength="500"  v-model="form.pic3_name">
          </div>
        </div>
        <div class="formGroup" v-if="event.cf_number !== ''">
          <div class="label">인증번호</div>
          <div class="value">
            <input type="text" class="input" placeholder="인증번호를 입력하세요" v-model="form.cf_number">
          </div>
        </div>
        <div class="formGroup" v-if="event.user_addr === 'y'">
          <div class="label">우편번호</div>
          <div class="value">
            <input type="text" class="input" placeholder="우편번호를 검색하세요" disabled v-model="form.zipcode">
            <button type="button" class="btn compact mt10" @click="searchAddr">우편번호 검색</button>
          </div>
        </div>
        <div class="formGroup" v-if="event.user_addr === 'y'">
          <div class="label">주소</div>
          <div class="value" >
            <input type="text" class="input" placeholder="우편번호를 검색하세요" disabled v-model="form.addr_1" >
          </div>
        </div>
        <div class="formGroup" v-if="event.user_addr === 'y'">
          <div class="label">상세주소</div>
          <div class="value">
            <input type="text" class="input" placeholder="상세주소를 입력하세요 " v-model="form.addr_2">
          </div>
        </div>
        <div class="formGroup" v-if="event.pic1_st === 'y'">
          <div class="label">첨부파일</div>
          <div class="value">
            <div class="fileBox">
              <label v-if="file1" class="fileInput" for="upload_file1">{{file1}}</label>
              <label v-else class="uploadBox" for="upload_file1">업로드를 통해 업로드하세요</label>
              <input type="file" id="upload_file1" name="file1" ref="file1" @change="fileUpload" accept=".jpeg,.jpg,.png,.gif,.hwp">
              <div class="fileBtnBox mouseCursor" v-show="file1" ><img src="@/assets/img/ico_cancel.png" @click="deleteFile('file1')"></div>
            </div>
            <div class="uploadBox" v-show="!file1">
              <label for="upload_file1" class="btn compact mt10 mouseCursor">첨부파일 업로드</label>
            </div>
          </div>
        </div>
        <div class="formGroup" v-if="event.pic2_st === 'y'">
          <div class="label">첨부파일</div>
          <div class="value">
            <div class="fileBox">
              <label v-if="file2" class="fileInput" for="upload_file2">{{file2}}</label>
              <label v-else class="uploadBox" for="upload_file2">업로드를 통해 업로드하세요</label>
              <input type="file" id="upload_file2" name="file2" ref="file2" @change="fileUpload" accept=".jpeg,.jpg,.png,.gif,.hwp">
              <div v-show="file2" class="fileBtnBox mouseCursor"><img src="@/assets/img/ico_cancel.png" @click="deleteFile('file2')"></div>
            </div>
            <div class="uploadBox" v-show="!file2">
              <label for="upload_file2" class="btn compact mt10 mouseCursor">첨부파일 업로드</label>
            </div>
          </div>
        </div>
        <div class="formGroup" v-if="event.pic3_st === 'y'">
          <div class="label">첨부파일</div>
          <div class="value">
            <div class="fileBox">
              <label v-if="file3" class="fileInput" for="upload_file3">{{file3}}</label>
              <label v-else class="uploadBox" for="upload_file3">업로드를 통해 업로드하세요</label>
              <input type="file" id="upload_file3" name="file3" ref="file3" @change="fileUpload" accept=".jpeg,.jpg,.png,.gif,.hwp">
              <div class="fileBtnBox mouseCursor" v-show="file3" ><img src="@/assets/img/ico_cancel.png" @click="deleteFile('file3')"></div>
            </div>
            <div class="uploadBox" v-show="!file3">
              <label for="upload_file3" class="btn compact mt10 mouseCursor">첨부파일 업로드</label>
            </div>
          </div>
        </div>
        <!-- agree -->
        <div class="mt20">
          <p class="mb10" style="padding-left: 15px; font-weight: 500">
            개인정보 수집 및 이용
            <span class="colorRed">(필수)</span>
          </p>
          <div class="input mb10">
            <div class="agreeBox fz14 light colorGrey" v-html="event.data_terms_content"></div>
          </div>
          <div class="agreeCheck">
            <span class="checkbox">
              <input type="radio" name="agree_privacy" id="agree_privacy_Y" value="Y" checked v-model="agree">
              <label for="agree_privacy_Y">동의</label>
            </span>
            <span class="checkbox">
              <input type="radio" name="agree_privacy" id="agree_privacy_N" value="N" v-model="agree">
              <label for="agree_privacy_N">미동의</label>
            </span>
          </div>
        </div>
        <!-- //agree -->
      </div>
      <button type="button" class="btn full mt30" @click="submit">참여하기</button>
      <button type="button" class="btn lightGrey full mt20" @click="goBack">이벤트 리스트</button>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import regExp from '@/common/regExp'
import terms from '@/library/terms'
import EagleBox from '@/common/EagleBox.vue'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

export default {
  name: 'Viewer',
  components: {EagleBox},
  data () {
    return {
      eventIdx: null,
      event: [],
      privacyAgreeText: terms.privacyAgreeText,
      mobile: '',
      form: {
        name: '',
        tel: '',
        pic1_name: '',
        pic2_name: '',
        pic3_name: '',
        cf_number: '',
        zipcode: '',
        addr_1: '',
        addr_2: '',
        detailAddr: ''
      },
      btnTmp: {
        title: '해당 이벤트는 아직 신청전 입니다.',
        subTitle: '이벤트 시간에 맞춰 참여해주세요.',
        isBtn: true,
        btnName: '페이지 새로고침',
        btnType: 'refrash'
      },
      isList: true,
      onEvent: false,
      agree: '',
      file1: '',
      file2: '',
      file3: '',
      stateTmp: ''
    }
  },
  created () {
    this.eventIdx = this.$route.params.id
    this.fetchData()
    this.init()
  },
  methods: {
    init () {
      api.get('/user/detail').then(res => {
        const user = res.data.data.user
        const regMobile = /[-]/g
        if (user) {
          this.mobile = user.mobileno
          this.form.name = user.name
          this.form.tel = user.mobileno.replace(regMobile, '')
        }
      })
    },
    fetchData () {
      api.get(`/event/${this.eventIdx}`).then(res => {
        if (!res.data.apply) {
          alert('해당 이벤트 참여 조건에 부합하지 않습니다.')
          this.$router.push({ name: 'eventHistory' })
        }
        const event = res.data.event
        const nowTime = dayjs()
        event.setStartApply = dayjs(event.start_apply).format('YYYY-MM-DD HH:mm')
        event._start_apply = dayjs(event.start_apply).format('YYYY.MM.DD HH:mm')
        event._end_apply = dayjs(event.end_apply).format('YYYY.MM.DD HH:mm')
        event.setEndApply = dayjs(event.end_apply).format('YYYY-MM-DD HH:mm')
        event.guide_contents_html = event.guide_contents //.replace(/(?:\r\n|\r|\n)/g, '<br>')
        if (nowTime >= dayjs(event.setStartApply)) {
          this.onEvent = true
          this.stateTmp = '진행중'
        }
        if (nowTime < dayjs(event.setStartApply)) {
          this.stateTmp = '진행예정'
        }
        if (dayjs().isSameOrAfter(event.setEndApply)) {
          this.stateTmp = '종료'
          this.btnTmp.title = '해당 이벤트는 종료되었습니다.'
          this.btnTmp.subTitle = ''
          this.btnTmp.btnName = '이전 페이지로 이동'
          this.btnTmp.btnType = 'back'
          this.onEvent = false
        }
        this.event = event
      })
    },
    searchAddr () {
      new window.daum.Postcode({
        oncomplete: (data) => {
          if (data.userSelectedType === 'R') {
            // 도로명 주소(R)
            this.form.addr_1 = data.roadAddress
          } else {
            // 지번 주소(J)
            this.form.addr_1 = data.jibunAddress
          }

          this.form.zipcode = data.zonecode
        }
      }).open()
    },
    validate () {
      let result = true
      if (regExp.replaceAllEmpty(this.form.name) === '' || this.form.tel === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.event.pic1_name !== '' && regExp.replaceAllEmpty(this.form.pic1_name) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.event.pic2_name !== '' && regExp.replaceAllEmpty(this.form.pic2_name) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.event.pic3_name !== '' && regExp.replaceAllEmpty(this.form.pic3_name) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.event.cf_number !== '' && regExp.replaceAllEmpty(this.form.cf_number) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.event.user_addr === 'y' && (this.form.addr_1 === '' || this.form.addr_2 === '' || this.form.zipcode === '' || regExp.replaceAllEmpty(this.form.addr_2) === '')) {
        alert('누락된 항목이 있습니다.')
        result = false
        // } else if (this.event.pic1_st === 'y' && regExp.replaceAllEmpty(this.file1) === '') {
        //   alert('누락된 항목이 있습니다.')
        //   result = false // 추후 주석해제
      } else if (this.event.pic2_st === 'y' && regExp.replaceAllEmpty(this.file2) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.event.pic3_st === 'y' && regExp.replaceAllEmpty(this.file3) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      }
      return result
    },
    submit () {
      if (dayjs().isSameOrAfter(this.event.end_apply)) {
        alert('종료된 이벤트입니다.')
        return this.$router.push({ name: 'eventHistory' })
      }
      if (this.validate()) {
        if (this.agree === '') {
          alert('개인정보 수집 및 이용 동의는 필수 선택 항목입니다.')
          return
        } if (this.agree === 'N') {
          alert('개인정보 수집 및 이용을 미동의할 경우 이벤트 참여에 제한됩니다.')
          return
        }

        const formData = new FormData()

        formData.append('apply_name', this.form.name)
        formData.append('apply_mobile', this.form.tel)

        if (this.form.pic1_name) {
          formData.append('add_info1', this.form.pic1_name)
        }
        if (this.form.pic2_name) {
          formData.append('add_info2', this.form.pic2_name)
        }
        if (this.form.pic3_name) {
          formData.append('add_info3', this.form.pic3_name)
        }
        if (this.form.cf_number) {
          formData.append('cf_number', this.form.cf_number)
        }
        if (this.form.zipcode) {
          formData.append('zipcode', this.form.zipcode)
          formData.append('addr_1', this.form.addr_1)
          formData.append('addr_2', this.form.addr_2)
        }
        if (this.file1) {
          formData.append('event_pic1', this.$refs.file1.files[0])
        }
        if (this.file2) {
          formData.append('event_pic2', this.$refs.file2.files[0])
        }
        if (this.file3) {
          formData.append('event_pic3', this.$refs.file3.files[0])
        }

        api.post(`/event/${this.eventIdx}/apply`, formData).then(res => {
          if (res.data.err) {
            alert(res.data.msg)
            return
          }
          alert('이벤트 참여가 완료되었습니다.\n참여해 주셔서 감사합니다.')
          this.$router.push({ name: 'eventHistory' })
        })
      }
    },
    fileUpload (e) {
      const file = e.target.files[0]
      const filename = file.name
      const nameLen = filename.lastIndexOf('.')
      const fullLen = filename.length
      const fileType = filename.substring(nameLen + 1, fullLen).toLowerCase()
      const maxSize = 10 * 1024 * 1024 // 10MB
      if (e.target.files[0].size > maxSize) {
        return alert('첨부파일은 10MB 이하의 용량만 첨부 가능합니다.')
      }
      const arr = ['jpeg', 'jpg', 'png', 'gif', 'hwp']
      if (!arr.includes(fileType)) {
        alert('첨부파일은 JPG, JPEG, PNG, GIF, HWP 파일로만 가능합니다.')
        this.fileReset()
        return
      }

      switch (e.target.name) {
        case 'file1': this.file1 = file.name; break
        case 'file2': this.file2 = file.name; break
        case 'file3': this.file3 = file.name; break
      }
    },
    deleteFile (name) {
      if (confirm('첨부파일을 삭제하시겠습니까?')) {
        switch (name) {
          case 'file1':
            console.log('asdf')
            this.$refs.file1.value = ''
            this.file1 = ''
            break
          case 'file2':
            this.$refs.file2.value = ''
            this.file2 = ''
            break
          case 'file3':
            this.$refs.file3.value = ''
            this.file3 = ''
            break
        }
      }
    },
    fileReset () {
      this.$refs.file1 = null
      this.$refs.file2 = null
      this.$refs.file3 = null
      this.file1 = ''
      this.file2 = ''
      this.file3 = ''
    },
    goBack () {
      this.$router.go(-1) // 이전 페이지로 이동
    }
  }
}
</script>
