<template>
  <div id="app" data-app>
    <div class="gnbBox">
      <Header :isLogin="isLogin" :style="headerStyle" /></div>
    <div class="mainContainer">
      <div
        v-if="setWidth"
        class="forPc"
        :style="bannerList.length > 0 ? { top: '20vh' } : { top: '40%' }"
      >

      <div class="logoBox">
          <img src="@/assets/img/ico_main_verPC.png" class="logoImg">
          <p class="titleText">경남FC 멤버십서비스</p>
          <p class="subtitleText"><span class="fw800">G </span>MEMBERS</p></div>
        <div class="bannerTitleText">
          <p class="bannerTitleText" v-show="bannerList.length > 0">G멤버스 이벤트</p>
          <div ref="carousel" class="bannerSlide">
            <carousel
              :perPage="1"
              :paginationActiveColor="'#515257'"
              :pagination-color="'rgba(81, 82, 87, 0.2)'"
              :paginationPadding="2"
              :autoplay="true"
              :autoplayTimeout="3000"
              :loop="true"
              :mouse-drag="false"
              :navigationEnabled="true">
              <slide
                v-for="(slide, index) in bannerList"
                :key="index"
                :data-index="index"
                class="slide">
                <div class="bg">
                  <a :href="slide.link" :target="slide.setLingType">
                  <img :src="slide.img" :alt="slide.title || ''" />
                  </a>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div :id="setWidth ? 'newContents' : 'contents'">
        <router-view :isLogin="isLogin" />
      </div>
    </div>
    <div class="mainContainer">
    <Footer :style="headerStyle"/>
    </div>
  </div>
</template>

<script>
import Header from '@/views/Header'
import Footer from '@/views/Footer'
import http from '@/api/index'
import dayjs from 'dayjs'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  computed: {
    isLogin () {
      return this.$store.getters['isLogin']
    }
  },
  watch: {
    width () {
      if (this.width >= 1000) {
        this.setWidth = true
        this.headerStyle = { 'margin-left': '485px' }
      } else {
        this.headerStyle = {}
        this.setWidth = false
      }
    }
  },
  created () {
    const token = localStorage.getItem('token')
    if (navigator.userAgent.includes('gmembers')) {
      // alert('App.vue token: ' + token)
    }
    if (token) {
      this.$store.commit('login', true)
    }
    this.init()
    const thisYear = dayjs().year()
    this.$store.commit('setThisYear', thisYear)
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.width >= 1000 ? this.setWidth = true : this.setWidth = false
    if (this.width >= 1000) {
      this.setWidth = true
      this.$nextTick(() => {
        const carousel = this.$refs.carousel
        setTimeout(() => {
          const pager = carousel.querySelector('.VueCarousel-pagination .VueCarousel-dot-container')
          const prev = carousel.querySelector('.VueCarousel-navigation .VueCarousel-navigation-prev')
          const next = carousel.querySelector('.VueCarousel-navigation .VueCarousel-navigation-next')
          pager.prepend(prev)
          pager.append(next)
        }, 500)
      })
      this.headerStyle = { 'margin-left': '485px' }
    } else {
      this.headerStyle = {}
      this.setWidth = false
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  data () {
    return {
      headerStyle: {},
      width: window.innerWidth,
      setWidth: '',
      bannerList: []
    }
  },
  methods: {
    handleResize () {
      this.width = window.innerWidth
      this.width >= 1000 ? this.setWidth = true : this.setWidth = false
    },
    init () {
      http.get('/event/banner').then(res => {
        const data = res.data.data

        this.bannerList = data.filter(el => el.state !== 'P')
        // eslint-disable-next-line array-callback-return
        this.bannerList.map(el => {
          if (el.link_type && el.link_type === 'Y') {
            el.setLingType = '_blank'
          }
          if (el.link_type && el.link_type === 'N') {
            el.setLingType = '_self'
          }
        })
      })
    }
  }
}
</script>
